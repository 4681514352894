<template>
  <div>
    <nav class="threesixty-global-nav" aria-label="Global external sites">
      <ul role="list">
        <li role="listitem">
          <a href="https://www.360giving.org/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" aria-hidden="true" viewBox="0 0 24 24">
              <path fill="#fff" d="M6.257 20.663a2.244 2.244 0 1 0 0-4.488 2.244 2.244 0 0 0 0 4.488ZM6.349 7.216a2.244 2.244 0 1 0 0-4.488 2.244 2.244 0 0 0 0 4.488ZM12.992 4.957a1.617 1.617 0 1 0 0-3.234 1.617 1.617 0 0 0 0 3.234ZM12.992 21.69a1.617 1.617 0 1 0 0-3.234 1.617 1.617 0 0 0 0 3.234ZM4.17 13.324a1.617 1.617 0 1 0 0-3.234 1.617 1.617 0 0 0 0 3.234ZM18.682 18.411a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM13.105 11.696a.655.655 0 0 0-.608.363v.343c0 .625.217.938.649.937a.528.528 0 0 0 .437-.234.955.955 0 0 0 .174-.587.938.938 0 0 0-.18-.593.57.57 0 0 0-.472-.23ZM8.932 11.204c.428 0 .642-.21.642-.628a.546.546 0 0 0-.154-.4.583.583 0 0 0-.431-.154.619.619 0 0 0-.396.132.404.404 0 0 0-.168.33h-.978a5.69 5.69 0 0 0-.031 2.296h.939a.515.515 0 0 0 .183.394c.124.111.285.17.452.166a.678.678 0 0 0 .48-.168.561.561 0 0 0 .18-.43c0-.249-.063-.426-.187-.53-.125-.104-.297-.156-.516-.156H8.39v-.852h.542ZM17.109 10.025a.455.455 0 0 0-.43.245c-.09.163-.137.41-.144.74v1.263c0 .369.046.639.14.81a.466.466 0 0 0 .44.256.46.46 0 0 0 .432-.25c.093-.166.14-.426.143-.78v-1.225c0-.355-.047-.62-.14-.796a.465.465 0 0 0-.441-.263Z" />
              <path fill="#fff" d="M17.116 14.22c-.55 0-.976-.182-1.277-.547-.301-.364-.452-.878-.451-1.542v-.9c0-.667.15-1.182.45-1.545.3-.362.723-.543 1.271-.543.48 0 .865.14 1.156.42a5.686 5.686 0 0 0-10.517-.039 1.62 1.62 0 0 1 .354-.213c.267-.117.556-.175.847-.172.55 0 .982.126 1.297.378.315.251.472.596.472 1.035-.001.216-.07.425-.195.6-.145.202-.342.36-.569.46.243.082.458.232.618.434.154.207.233.461.224.72a1.278 1.278 0 0 1-.509 1.06c-.338.262-.784.394-1.338.394-.31.002-.617-.06-.9-.185a1.632 1.632 0 0 1-.31-.18 5.687 5.687 0 0 0 10.556-.08c-.294.297-.687.445-1.18.445Zm-2.433-.84c-.146.26-.361.473-.622.616-.27.15-.575.227-.884.224a1.847 1.847 0 0 1-.941-.24 1.648 1.648 0 0 1-.645-.684 2.256 2.256 0 0 1-.24-1.026v-.458c-.007-.478.107-.95.33-1.372.214-.401.538-.733.935-.956.411-.232.876-.352 1.348-.347h.174v.9h-.051c-.428 0-.78.103-1.054.308a1.293 1.293 0 0 0-.508.854 1.337 1.337 0 0 1 .975-.38c.428 0 .768.157 1.02.47.252.312.378.722.38 1.229.005.302-.07.601-.217.866v-.004Z" />
            </svg>

            <span>360Giving.org</span>
          </a>
        </li>
        <li role="listitem">
          <a href="https://grantnav.threesixtygiving.org/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" aria-hidden="true" viewBox="0 0 24 24">
              <path fill="#DE6E26" d="M12.478 4c-3.556 0-6.519 2.976-6.519 6.548 0 1.31.356 2.5 1.067 3.571l-4.03 3.572L4.182 19l4.03-3.452c1.184 1.071 2.607 1.666 4.266 1.666 3.556 0 6.519-2.976 6.519-6.547 0-3.69-2.963-6.667-6.52-6.667Zm0 11.31c-2.607 0-4.74-2.143-4.74-4.762 0-2.62 2.133-4.762 4.74-4.762s4.74 2.143 4.74 4.762c0 2.619-2.133 4.761-4.74 4.761Z" />
            </svg>

            <span>GrantNav</span>
          </a>
        </li>
        <li role="listitem">
          <a href="https://insights.threesixtygiving.org/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" aria-hidden="true" viewBox="0 0 24 24">
              <g fill="#5FB4BD" clip-path="url(#a)">
                <path d="M7.996 9h-4v11h4V9ZM19.997 13h-4v7h4v-7ZM13.996 4h-4v16h4V4Z" />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M-.004 0h24v24h-24z" />
                </clipPath>
              </defs>
            </svg>

            <span>360Insights</span>
          </a>
        </li>
        <li role="listitem" class="threesixty-global-nav__wider-container">
          <a href="https://www.ukgrantmaking.org/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" aria-hidden="true" viewBox="0 0 24 24">
              <path fill="#9433EF" d="M11.435 13.887c.344 2.021-1.03 3.936-3.07 4.277-2.039.341-3.972-1.02-4.316-3.041-.344-2.021 1.03-3.936 3.07-4.277 2.039-.34 3.972 1.02 4.316 3.041Z" />
              <path fill="#C999F7" d="M15.531 12.766c.17.985-.509 1.919-1.516 2.085-1.007.166-1.96-.498-2.131-1.483-.17-.985.509-1.919 1.516-2.085 1.006-.166 1.96.498 2.131 1.483Z" />
              <path fill="#861AED" d="M19.967 14.204a2.087 2.087 0 0 1-1.705 2.406 2.087 2.087 0 0 1-.693-4.116 2.08 2.08 0 0 1 2.398 1.71Z" />
              <path fill="#7900EB" d="M16.838 10.345c.09.556-.267 1.083-.796 1.177-.528.094-1.029-.281-1.119-.837-.089-.555.268-1.082.796-1.176.529-.094 1.03.281 1.12.836Z" />
              <path fill="#D7B2F9" d="M16.535 17.754c.26 1.515-.776 2.951-2.312 3.207-1.536.256-2.99-.766-3.25-2.281-.26-1.515.775-2.951 2.311-3.208 1.536-.255 2.991.766 3.25 2.281v.001Z" />
              <path fill="#BC80F5" d="M15.499 6.403c.379 2.223-1.132 4.33-3.372 4.704-2.242.375-4.364-1.122-4.743-3.345-.379-2.224 1.132-4.33 3.373-4.704 2.24-.375 4.364 1.122 4.742 3.345Z" />
            </svg>

            <span>UKGrantmaking</span></a>
        </li>
        <li role="listitem" class="threesixty-global-nav__current">
          <a href="https://qualitydashboard.threesixtygiving.org/alldata" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path fill="#EFC329" d="M11.358 3.192a1.16 1.16 0 0 1 1.28 0l.625.412c.211.137.457.204.707.19l.749-.046c.464-.028.9.225 1.107.64l.338.671c.112.225.295.404.516.517l.679.34c.415.208.668.644.64 1.108l-.046.749c-.014.25.053.5.19.707l.415.625a1.16 1.16 0 0 1 0 1.28l-.415.63c-.137.21-.204.456-.19.706l.046.749c.028.464-.225.9-.64 1.107l-.672.337a1.166 1.166 0 0 0-.516.517l-.341.679a1.16 1.16 0 0 1-1.108.64l-.748-.046c-.25-.014-.5.053-.707.19l-.626.414a1.16 1.16 0 0 1-1.28 0l-.629-.414a1.173 1.173 0 0 0-.706-.19l-.75.045a1.16 1.16 0 0 1-1.106-.64l-.338-.67a1.166 1.166 0 0 0-.517-.518l-.678-.34a1.16 1.16 0 0 1-.64-1.108l.046-.749c.014-.25-.053-.499-.19-.706l-.411-.63a1.16 1.16 0 0 1 0-1.28l.41-.625c.138-.21.205-.457.19-.706l-.045-.75c-.028-.463.225-.9.64-1.107l.671-.337a1.19 1.19 0 0 0 .52-.524l.338-.671a1.161 1.161 0 0 1 1.107-.64l.75.046c.249.014.498-.053.706-.19l.629-.412ZM14.81 9.75a2.812 2.812 0 1 0-5.625 0 2.812 2.812 0 0 0 5.625 0ZM5.294 18.53l1.515-3.603c.007.003.01.007.014.014l.338.671a2.293 2.293 0 0 0 2.18 1.259l.748-.046c.007 0 .018 0 .025.007l.625.415c.18.116.37.207.566.27l-1.321 3.14a.556.556 0 0 1-.468.34.558.558 0 0 1-.52-.252l-1.132-1.733-1.972.291a.56.56 0 0 1-.601-.773h.003Zm8.718 2.123-1.322-3.135c.197-.064.387-.152.566-.271l.626-.415c.007-.003.014-.007.025-.007l.748.046a2.293 2.293 0 0 0 2.18-1.259l.337-.671a.028.028 0 0 1 .014-.014l1.52 3.603a.57.57 0 0 1-.075.563.556.556 0 0 1-.527.21l-1.972-.291L15 20.742a.563.563 0 0 1-.988-.088Z" />
            </svg>
            <span>Data Quality Dashboard</span></a>
        </li>
      </ul>
    </nav>

    <div class="nav-bar nav-bar--minimal">
      <a class="nav-bar__home-button" href="/">
        <img src="@/assets/images/360-logos/360dataqualitydashboard-color.svg" alt="360DataQualityDashboard" style="width: unset; height:94px">
      </a>

      <nav class="nav-bar__nav-menu">
        <ul>
          <li class="nav-menu__item">
            Based on data published by UK grantmakers in the 360Giving Data Standard
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseNav",
}
</script>
